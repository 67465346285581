import { Tenant } from '@/app/interfaces/api/tenant';

export interface TenantFeature {
  id: number;
  tenantId: number;
  tenant: Tenant | null;
  featureId: number;
  feature: Feature | null;
}

export interface Feature {
  id: number;
  name: string;
}

export enum FeatureType {
  None = 0,
  Tenant = 1,
  Hardware = 2
}