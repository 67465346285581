import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CoreModule } from '@/shared/lib/modules/core.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-loader',
  templateUrl: './ods-loader.component.html',
  styleUrl: './ods-loader.component.scss',
  standalone: true,
  imports: [CoreModule],
})

export class OdsLoaderComponent {
}
