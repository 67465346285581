import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable(
    {
      providedIn: 'root'
    }
)
export class DateExtension {

    constructor(public datepipe: DatePipe) {
    }

    formatDateFromString(inputDate: any) {
        if (inputDate != null && inputDate !== undefined) {
            if (inputDate.slice(-1) !== 'Z') {
                inputDate = inputDate + 'Z';
            }

            return this.datepipe.transform(new Date(inputDate), 'yyyy-MM-dd HH:mm:ss');
        }
        return;
    }

    formatDateFromDateTime(inputDate: any) {
        return new Date(inputDate).toLocaleString();
    }

    formatDateFromStringToLocale(inputDate: any) {
        if (inputDate != null && inputDate !== undefined) {
            if (inputDate.slice(-1) === 'Z') {
                return new Date(inputDate).toLocaleString();
            }

            return new Date(inputDate + 'Z').toLocaleString();

        }
        return;
    }

    formatDateFromDateTimeToLocale(inputDate: any) {
        return new Date(inputDate).toLocaleString();
    }

    addDaysToTodayAsDateString(numberOfDays: number) {
        return this.datepipe.transform(new Date(new Date().setDate(new Date().getDate() + numberOfDays)), 'yyyy-MM-dd');
    }
}
