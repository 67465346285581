import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { isNotNullOrEmpty, isNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { UserStore } from '@/shared/lib/stores/user.store';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { NgOptimizedImage } from '@angular/common';
import { TenantDeploymentPage } from '@/app/dialogs/tenant-deployment/tenant-deployment.page';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css',
  standalone: true,
  imports: [CoreModule, RouterLink, NgOptimizedImage],
})
export class NavComponent {
  dialog = inject(MatDialog);
  userStore = inject(UserStore);
  deploymentStore = inject(DeploymentStore);

  logout() {
    this.userStore.clear();
    this.deploymentStore.clear();
    window.location.reload();
  }

  roleBasedNavLinks = computed(() => {
    return this.navLinks.map(navLink => ({
      ...navLink,
      subItems: navLink.subItems.filter(subItem => this.userStore.hasUserRole(subItem.role)),
    })).filter(navLink => navLink.subItems.length > 0);
  });

  presentTenantDeploymentModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    return this.dialog.open(TenantDeploymentPage, dialogConfig);
  }

  navLinks = [
    {
      subHeader: '',
      subItems: [
        {
          label: 'menu.home.short',
          icon: '../../../assets/svg/home.svg',
          to: '/',
          role: 'applicationHome',
        },
      ],
    },
    {
      subHeader: '',
      subItems: [
        {
          label: 'tenantDeployment',
          icon: '../../../assets/svg/briefcase.svg',
          to: null,
          role: 'applicationHome',
        },
      ],
    },
    {
      subHeader: 'menu.actions.long',
      subItems: [
        {
          label: 'menu.history.short',
          icon: '../../../assets/svg/scan-report.svg',
          to: '/deployment/history',
          role: 'deploymentHistory',
        },
        {
          label: 'menu.upload.short',
          icon: '../../../assets/svg/archive.svg',
          to: '/deployment/upload',
          role: 'deploymentUpload',
        },
        {
          label: 'menu.planning.short',
          icon: '../../../assets/svg/event.svg',
          to: '/deployment/planning',
          role: 'deploymentUpload',
        },
      ],
    },
    {
      subHeader: 'menu.tenant.long',
      subItems: [
        {
          label: 'menu.devices.short',
          icon: '../../../assets/svg/settings-horizontal.svg',
          to: '/properties/devices',
          role: 'propertiesRead',
        },
        {
          label: 'menu.deployments.short',
          icon: '../../../assets/svg/settings-vertical.svg',
          to: '/properties/deployments',
          role: 'propertiesRead',
        },
        {
          label: 'menu.account.long',
          icon: '../../../assets/svg/account.svg',
          to: '/account/list',
          role: 'userManagement',
        },
      ],
    },
    {
      subHeader: 'menu.support.long',
      subItems: [
        {
          label: 'menu.support-devices.long',
          icon: '../../../assets/svg/more.svg',
          to: '/configuration/devices',
          role: 'supportDevices',
        },
        {
          label: 'menu.tenants.long',
          icon: '../../../assets/svg/account.svg',
          to: '/configuration/tenants',
          role: 'supportTenants',
        },
        {
          label: 'menu.hardwaretypes.long',
          icon: '../../../assets/svg/account.svg',
          to: '/configuration/hardwaretypes',
          role: 'supportTenants',
        },
      ],
    },
    {
      subHeader: 'menu.analyze.long',
      subItems: [
        {
          label: 'menu.analyze-sor.long',
          icon: '../../../assets/svg/continues.svg',
          to: '/analyze/sor',
          role: 'sorAnalyzer',
        },
      ],
    },
  ];

  protected readonly isNotNullOrEmpty = isNotNullOrEmpty;
  protected readonly isNullOrEmpty = isNullOrEmpty;
}
