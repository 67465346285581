import 'iconify-icon';

import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ods-ui-icon',
  standalone: true,
  templateUrl: './icon.component.html',
})
export class IconComponent {
  icon = input.required<string>();
  rotate = input<'90deg' | '180deg' | '270deg'>();
}
