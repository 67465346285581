@if (this.userStore.isAuthenticated()) {
  <mat-list>
    <mat-list-item>
    <span class="fullwidth">
      <span class="mat-h2">{{ userStore.userProfile()?.name }}</span>
      <span class="float-right" style="width: 75px; cursor: pointer;" (click)="logout()">
        <mat-icon>logout</mat-icon><span class="aligned-with-icon">{{ 'menu.account.logout' | translate }}</span>
      </span>
    </span>
    </mat-list-item>
    <mat-divider/>
    @for (link of roleBasedNavLinks(); track link.subHeader) {
      <mat-divider/>
      @if (isNotNullOrEmpty(link.subHeader)) {
        <div mat-subheader>{{ link.subHeader | translate }}</div>
      }
      @for (subItem of link.subItems; track subItem.label) {
        @if (isNullOrEmpty(subItem.to) && subItem.label === 'tenantDeployment') {
          <mat-list-item class="showAsLink" (click)="presentTenantDeploymentModal()" style="height:50px; vertical-align: center">
            <img alt="" class="cloudotdr-icon-small" width="24" height="24" ngSrc="{{subItem.icon}}"/>
            <span class="tenant-deployment">
              {{ this.deploymentStore.selectedTenant()?.name }} <br />
              {{ this.deploymentStore.selectedDeployment()?.name }}
            </span>
          </mat-list-item>
        } @else if (isNotNullOrEmpty(subItem.to)) {
          <mat-list-item class="showAsLink" [routerLink]="[subItem.to]">
            <div>
              <img alt="" class="cloudotdr-icon-small" width="24" height="24" ngSrc="{{subItem.icon}}"/>
              <span class="aligned-with-icon">
                  {{ subItem.label | translate }}
              </span>
            </div>
          </mat-list-item>
        }
      }
    }
  </mat-list>
}
