import { computed, inject } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';

import { Deployment } from '../../../../web-app/src/app/interfaces/api/deployment';
import { Tenant } from '../../../../web-app/src/app/interfaces/api/tenant';
import { DeploymentsApi } from '../../../../web-app/src/app/services/api/deployments.api';
import { storagePrefix } from '../constants/storage.const';

type DeploymentState = {
  selectedTenant: Tenant | null;
  tenantList: Tenant[] | null;
  selectedDeployment: Deployment | null;
  deploymentList: Deployment[] | null;
  deploymentIsLoading: boolean;
};

const initialState: DeploymentState = {
  selectedTenant: null,
  tenantList: [],
  selectedDeployment: null,
  deploymentList: [],
  deploymentIsLoading: false,
};

export const DeploymentStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      selectedTenantId: computed(() => {
        return store.selectedTenant()?.id;
      }),
      selectedDeploymentId: computed(() => {
        return store.selectedDeployment()?.id;
      }),
    };
  }),
  withMethods((store, deploymentsApi = inject(DeploymentsApi)) => ({
    setTenantList(tenantList: Tenant[]) {
      patchState(store, { tenantList });
      if (tenantList.length === 1) {
        this.setTenant(tenantList[0].id);
      }
    },
    setTenant(tenant?: number) {
      if (isNotNullOrEmpty(tenant)) {
        if (store.selectedTenantId() !== tenant) {
          patchState(store, { selectedTenant: store.tenantList()?.find((t) => t.id === tenant), selectedDeployment: null, deploymentList: [], deploymentIsLoading: true });
          deploymentsApi.getDeploymentTree(tenant).subscribe((deployments: any) => {
            this.setDeploymentList(deployments);
          });
        }
      } else {
        patchState(store, { selectedTenant: null, selectedDeployment: null, deploymentList: [] });
      }
    },
    setDeploymentList(deploymentList?: Deployment[]) {
      patchState(store, { deploymentList: deploymentList, deploymentIsLoading: false });
      if (deploymentList?.length === 1) {
        this.setDeployment(deploymentList[0].id);
      }
    },
    setDeployment(deployment?: number) {
      if (isNotNullOrEmpty(deployment)) {
        if (store.selectedDeploymentId() !== deployment) {
          const deploymentFromList = store.deploymentList()?.find((d) => d.id === deployment);
          if (deploymentFromList?.tenantId === store.selectedTenantId()) {
            patchState(store, {selectedDeployment: deploymentFromList});
          }
        }
      } else {
        patchState(store, { selectedDeployment: null });
      }
    },
    clear() {
      patchState(store, { selectedDeployment: null, selectedTenant: null, tenantList: [], deploymentList: [] });
    }
  })),
  withStorageSync({
    key: `${storagePrefix}_deployment`,
    storage: () => localStorage,
  })
);
