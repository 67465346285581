import { IHierarchical } from '@/app/interfaces/api/hierarchical';

export class Hierarchical {
    
  public static getLevelClass<T extends IHierarchical>(item: T, items: T[]) {
    let className = 'hierarchical level-' + (item.level ?? 0);

    if (items.some(x => x.id == item.parentId))
      className += ' has-parent';

    if (items.some(x => x.parentId == item.id))
      className += ' has-child';

    const index = items.findIndex(x => x.id == item.id);
    const hasSibling = (item.level ?? 0) > 0 && index < items.length - 1 && items[index + 1].parentId == item.parentId;
    if (hasSibling)
      className += ' has-sibling';

    return className;
  }

  public static filterWithChildren<T extends IHierarchical>(item: T, items: T[]) {
    const idsToRemove = this.findAllDescendants(item, items).map(x => x.id);
    return items.filter(item => !idsToRemove.includes(item.id));
  }
  
  public static findAllDescendants<T extends IHierarchical>(item: T, items: T[]): T[] {
    const descendants = [item];  // Start with the given item ID
    const children = items.filter(x => x.parentId == item.id);

    // Recursively add all children and their descendants
    for (const child of children) {
        descendants.push(...this.findAllDescendants(child, items));
    }

    return descendants;
  }

  public static getMaxLevelDifference<T extends IHierarchical>(descendants: T[]): number {
    const levels = descendants.map(descendant => (descendant.level ?? 0));
    const minLevel = Math.min(...levels);
    const maxLevel = Math.max(...levels);
    
    return maxLevel - minLevel;
  }
  
  public static sortByLevel<T extends IHierarchical>(items: T[]) {
    items.forEach(item => {
      this.setLevel(item, items);
    });

    const sorted: T[] = [];
    this.insert(items.filter(x => x.level == 0), items, sorted);

    sorted.forEach(item => {
      item.className = this.getLevelClass(item, sorted);
    })

    return sorted;
  }

  private static insert<T extends IHierarchical>(subset: T[], items: T[], output: T[]) {
    subset.forEach(item => {
      output.push(item);
      this.insert(items.filter(x => x.parentId == item.id), items, output);
    })
  }
  
  private static setLevel<T extends IHierarchical>(item: T, items: T[]) {
    if (item.level != undefined)
      return;

    const parent = item.parentId ? items.find(x => x.id == item.parentId) : null;
    if (!parent) {
        item.level = 0;
      return;
    }

    if (parent.level == undefined)
      this.setLevel(parent, items);

    item.level = (parent.level ?? 0) + 1;
  }  
}