import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  return next(req).pipe(
    catchError((error) => {
      if (error.status === 304) {
        // We do not want this to be considered an error
        return EMPTY;
      } else {
        return next(req);
      }
    })
  );
};
