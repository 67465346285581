import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  router = inject(Router);

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;

    // this.checkLogin(url).then(authStatus => {
    //   return true;
    //   // if (authStatus) {
    //   //   this.checkRole(url).then(roleStatus => {
    //   //     if (roleStatus) {
    //   //       return true;
    //   //     } else {
    //   //       this.router.navigate(['/account/not-authorized']);
    //   //       return false;
    //   //     }
    //   //   });
    //   // } else {
    //   //   this.router.navigate(['/account/login']);
    //   //   return false;
    //   // }
    // });

    return true;
  }
}
