import { ChangeDetectionStrategy, Component, computed, inject, OnChanges, OnInit, signal } from '@angular/core';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@/app/pages/configuration/hardwaretypes/hardwaretypes.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-search-list',
  templateUrl: './search-list.component.html',
  standalone: true,
  imports: [CoreModule],
})
export class SearchListComponent implements OnInit, OnChanges {
  searchData = signal<any[] | null>([]);

  deploymentStore = inject(DeploymentStore);
  filteredItems = signal<any[]>([]);
  dialogData: DialogData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<SearchListComponent>);

  // @ts-ignore
  isTenantSelection = computed(() => this.dialogData.isTenantSelection);

  ngOnChanges() {
    // @ts-ignore
    this.searchData.set(this.dialogData.isTenantSelection ? this.deploymentStore.tenantList() : this.deploymentStore.deploymentList());
    this.dialogRef.updateSize('100%');
    this.filteredItems.set(this.searchData() ?? []);
  }

  ngOnInit() {
    // @ts-ignore
    this.searchData.set(this.dialogData.isTenantSelection ? this.deploymentStore.tenantList() : this.deploymentStore.deploymentList());
    this.filteredItems.set(this.searchData() ?? []);
  }

  setSelection(item: any) {
    this.dialogRef.close(item);
  }

  cancelChanges() {
    this.dialogRef.close(null);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: any | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems.set(this.searchData() ?? []);
    } else {

      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.target?.value.toLowerCase();
      this.filteredItems.set(this.searchData()?.filter((item) => {
        return item.name.toLowerCase().includes(normalizedQuery) || item.id.toString().includes(normalizedQuery);
      }) ?? []);
    }
  }

  protected readonly isNotNullOrEmpty = isNotNullOrEmpty;
}
