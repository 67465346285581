import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model, output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { CoreModule } from '@/shared/lib/modules/core.module';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [Button, CoreModule, DialogModule, NgTemplateOutlet],
  selector: 'ods-ui-modal',
  standalone: true,
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  header = input<string | undefined>(undefined);
  isVisible = model.required<boolean>();
  canDismiss = input(true);
  focusOnShow = input(true);
  headerTemplate = input<TemplateRef<unknown>>();
  footerTemplate = input<TemplateRef<unknown>>();
  modalForm = input<FormGroup | null>(null);
  readonly onVisibleChange = output<boolean>();
  readonly onSave = output<Event>();

  closeModal = () => {
    this.isVisible.set(false);
  };

  submitForm = (event: Event) => {
    this.onSave.emit(event);
  }

  onVisibilityChange = (value: boolean) => {
    this.onVisibleChange.emit(value);
  };
}
