import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthGuardService } from '@/app/services/authentication/auth-guard.service';
import { ODSRoute } from '@/shared/lib/interfaces/ods-route';
import { UserStore } from '@/shared/lib/stores/user.store';

const homePage: ODSRoute[] = [
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: {
      unProtected: false,
      permission: 'applicationHome',
    },
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent),
    data: {
      unProtected: false,
      permission: 'applicationHome',
    },
  }
];

const accountPages: ODSRoute[] = [
  {
    path: 'account/login',
    loadComponent: () => import('./pages/account/login/login.component').then(m => m.LoginComponent),
    data: {
      unProtected: true,
      permission: '',
    },
  },
  {
    path: 'account/forgot-credentials',
    loadComponent: () => import('./pages/account/forgot-credentials/forgot-credentials.component').then(m => m.ForgotCredentialsComponent),
    data: {
      unProtected: true,
      permission: '',
    },
  },
  {
    path: 'tenant/accounts',
    loadComponent: () => import('./pages/account/list/list.component').then(m => m.ListAccountComponent),
    data: {
      unProtected: false,
      permission: 'userManagement',
    },
  },
  {
    path: 'account/change-password/:id',
    loadComponent: () => import('./pages/account/change-password/change-password.component').then(m => m.ChangePasswordComponent),
    data: {
      unProtected: true,
      permission: '',
    },
  },
  {
    path: 'account/not-authorized',
    loadComponent: () => import('./pages/account/not-authorized/not-authorized.component').then(m => m.NotAuthorizedComponent),
    data: {
      unProtected: true,
      permission: '',
    },
  }
]

const analyzePages: ODSRoute[] = [
  {
    path: 'analyze',
    loadComponent: () => import('@/app/pages/group-landing/group-landing.component').then(m => m.GroupLandingComponent),
    data: {
      unProtected: false,
      permission: 'propertiesRead',
    },
  },
  {
    path: 'analyze/sor',
    loadComponent: () => import('./pages/analyze/sor/sor.component').then(m => m.SorComponent),
    data: {
      unProtected: false,
      permission: 'sorAnalyzer',
    },
  },
]

const supportPages: ODSRoute[] = [
  {
    path: 'support',
    loadComponent: () => import('@/app/pages/group-landing/group-landing.component').then(m => m.GroupLandingComponent),
    data: {
      unProtected: false,
      permission: 'propertiesRead',
    },
  },
  {
    path: 'support/devices',
    loadComponent: () => import('@/app/pages/support/devices/devices.component').then(m => m.DevicesComponent),
    data: {
      unProtected: false,
      permission: 'supportDevices',
    },
  },
  {
    path: 'support/tenants',
    loadComponent: () => import('@/app/pages/support/tenants/tenants.component').then(m => m.TenantsComponent),
    data: {
      unProtected: false,
      permission: 'supportTenants',
    },
  },
  {
    path: 'support/hardwaretypes',
    loadComponent: () => import('@/app/pages/support/hardwaretypes/hardwaretypes.component').then(m => m.HardwareTypesComponent),
    data: {
      unProtected: false,
      permission: 'supportTenants',
    },
  },
];

const deploymentPages: ODSRoute[] = [
  {
    path: 'deployment',
    loadComponent: () => import('@/app/pages/group-landing/group-landing.component').then(m => m.GroupLandingComponent),
    data: {
      unProtected: false,
      permission: 'propertiesRead',
    },
  },
  {
    path: 'deployment/history',
    loadComponent: () => import('./pages/deployment/history/history.component').then(m => m.HistoryComponent),
    data: {
      unProtected: false,
      permission: 'deploymentHistory',
    },
  },
  {
    path: 'deployment/planning',
    loadComponent: () => import('./pages/deployment/planning/planning.component').then(m => m.PlanningComponent),
    data: {
      unProtected: false,
      permission: 'deploymentUpload',
    },
  },
  {
    path: 'deployment/upload',
    loadComponent: () => import('./pages/deployment/upload/upload.component').then(m => m.UploadComponent),
    data: {
      unProtected: false,
      permission: 'deploymentUpload',
    },
  },
]

const tenantPages: ODSRoute[] = [
  {
    path: 'tenant',
    loadComponent: () => import('@/app/pages/group-landing/group-landing.component').then(m => m.GroupLandingComponent),
    data: {
      unProtected: false,
      permission: 'propertiesRead',
    },
  },
  {
    path: 'tenant/deployments',
    loadComponent: () => import('@/app/pages/tenant/deployments/deployments.component').then(m => m.DeploymentsComponent),
    data: {
      unProtected: false,
      permission: 'propertiesRead',
    },
  },
  {
    path: 'tenant/devices',
    loadComponent: () => import('@/app/pages/tenant/devices/devices.component').then(m => m.DevicesComponent),
    data: {
      unProtected: false,
      permission: 'propertiesRead',
    },
  },
]

const errorPages: ODSRoute[] = [
  {
    path: '**',
    loadComponent: () => import('./pages/error/error.component').then((c) => c.ErrorComponent),
    data: {
      unProtected: true,
      permission: '',
    },
  },
];


export const routes: ODSRoute[] = [
  ...homePage,
  ...supportPages,
  ...analyzePages,
  ...deploymentPages,
  ...accountPages,
  ...tenantPages,
  ...errorPages,
].map((page) => {
  if (page.redirectTo) {
    return {
      ...page,
      pathMatch: page.pathMatch ?? 'full',
    };
  } else {
    return {
      ...page,
      canActivate: [
        AuthGuardService,
        () => {
          const unProtected = page.data.unProtected;
          const role = page.data.permission;
          const router = inject(Router);
          const userStore = inject(UserStore);
          const isAuthenticated = userStore.isAuthenticated();

          // if unProtected, allow the route
          if (unProtected) {
            return true;
          }

          if (!isAuthenticated) {
            router.navigateByUrl('/account/login');
            return false;
          }

          if (userStore.hasUserRole(role)) {
            return true;
          } else {
            // router.navigateByUrl('');
            router.navigate(['/account/not-authorized']);
            return false;
          }
        },
      ],
    };
  }
});

