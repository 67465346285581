<h1 mat-dialog-title>
  <button mat-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</h1>
<div mat-dialog-content>
  <ods-single-select
    class="fullwidth"
    [isTenantSelection]="true"
  >
  </ods-single-select>
  @if (!this.deploymentStore.deploymentIsLoading()) {
    <ods-single-select
      class="fullwidth"
      [isTenantSelection]="false"
    >
    </ods-single-select>
  } @else {
    <ods-loader class="ods-spinner-medium"></ods-loader>
  }
</div>
