import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { SearchListComponent } from '@/app/dialogs/search-list/search-list.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-single-select',
  templateUrl: './single-select.component.html',
  standalone: true,
  imports: [CoreModule, SearchListComponent],
})
export class SingleSelectComponent {
  isTenantSelection = input<boolean>(true);
  deploymentStore = inject(DeploymentStore);
  modalController = inject(MatDialog);

  openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '750px';
    dialogConfig.data = {
      isTenantSelection: this.isTenantSelection(),
    };

    const modal = this.modalController.open(SearchListComponent, dialogConfig);

    modal.afterClosed().subscribe((data) => {
      if (isNotNullOrEmpty(data)) {
        if (this.isTenantSelection()) {
          this.deploymentStore.setTenant(data);
        } else {
          this.deploymentStore.setDeployment(data);
        }
      }
    });
  }


}
