import { ChangeDetectionStrategy, Component, computed, inject, input, OnChanges, OnInit, signal } from '@angular/core';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { Button } from 'primeng/button';
import { ModalComponent } from '@/shared/lib/components/ui/modal/modal.component';
import { IconComponent } from '@/shared/lib/components/ui/icon/icon.component';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ods-modal-single-select',
  templateUrl: './single-select.component.html',
  standalone: true,
  imports: [CoreModule, Button, ModalComponent, IconComponent, InputTextModule],
})
export class SingleSelectComponent implements OnInit, OnChanges {
  isTenantSelection = input<boolean>(true);
  deploymentStore = inject(DeploymentStore);
  isModalVisible = signal(false);

  searchData = computed(() => this.isTenantSelection() ? this.deploymentStore.tenantList() : this.deploymentStore.deploymentList());
  filteredItems = signal<any[]>([]);

  ngOnChanges() {
    this.filteredItems.set(this.searchData() ?? []);
  }

  ngOnInit() {
    this.filteredItems.set(this.searchData() ?? []);
  }

  hideModal() {
    this.isModalVisible.set(false);
  }

  filterList(searchQuery: any | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems.set(this.searchData() ?? []);
    } else {

      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.target?.value.toLowerCase();
      this.filteredItems.set(this.searchData()?.filter((item) => {
        return item.name?.toLowerCase().includes(normalizedQuery) || item.id.toString().includes(normalizedQuery);
      }) ?? []);
    }
  }

  setSelection(item: any) {
    if (this.isTenantSelection()) {
      this.deploymentStore.setTenant(item)
    } else {
      this.deploymentStore.setDeployment(item)
    }
    this.hideModal();
  }
}
