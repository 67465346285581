import { Component, inject, model, signal } from '@angular/core';
import { CoreModule } from '@/shared/lib/modules/core.module';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { SingleSelectComponent } from '@/app/dialogs/single-select/single-select.component';
import { OdsLoaderComponent } from '@/app/dialogs/ods-loader/ods-loader.component';
import { ModalComponent } from '@/shared/lib/components/ui/modal/modal.component';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'ods-modal-tenant-deployment',
  templateUrl: './tenant-deployment.page.html',
  standalone: true,
  imports: [CoreModule, SingleSelectComponent, OdsLoaderComponent, ModalComponent, SkeletonModule],
})

export class TenantDeploymentPage {
  protected deploymentStore = inject(DeploymentStore);
  isLoading = signal(false);
  isVisible = model.required<boolean>();

  hideModal() {
    this.isVisible.set(false);
  }
}
