import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Tenant } from '@/app/interfaces/api/tenant';
import { TenantEdit } from '@/app/interfaces/api/tenant-edit';
import { Feature, FeatureType } from '@/app/interfaces/api/tenant-feature';
import { Hierarchical } from '@/app/utils/hierarchical';
import { environment } from '@/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantsApi {
  http = inject(HttpClient);

  public getTenantByHeader(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${environment.data.baseUrl}/tenants/byheader`).pipe(map(result => {
      return result && result.length
        ? Hierarchical.sortByLevel(result)
        : result;
    }));
  }

  public getAll(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${environment.data.baseUrl}/tenants/all`).pipe(map(result => {
      return result && result.length
        ? Hierarchical.sortByLevel(result)
        : result;
    }));
  }

  public getAllFeatures(type: FeatureType = FeatureType.Tenant): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${environment.data.baseUrl}/features?type=${type}`);
  }

  public createTenant(tenant: TenantEdit): Observable<Tenant> {
    return this.http.post<Tenant>(`${environment.data.baseUrl}/tenants`, tenant);
  }

  public editTenant(tenant: TenantEdit): Observable<void> {
    return this.http.put<void>(`${environment.data.baseUrl}/tenants/${tenant.id}`, tenant);
  }

  public removeTenant(tenant: TenantEdit): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.data.baseUrl}/tenants/${tenant.id}`);
  }
}
