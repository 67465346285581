// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,

  identity: {
    baseUrl: 'https://api.cloudotdr.com/auth/v1',
    gatewayKey: '5caab2b1e71c4310b90fd4d1174d02fc',
    unprotectedCalls: ['LoginJwt', 'ForgotCredentials', 'ResetPassword']
  },

  data: {
    baseUrl: 'https://dev-api.cloudotdr.com/web/v1',
    gatewayKey: 'd1830a6308e2480792ef51a754a554fa',
  },

  numberOfDaysOfHistory: 10,
  environmentStoragePrefix: 'dev_',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
