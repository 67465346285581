import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { environment } from '../../../../web-app/src/environments/environment';
import {
  AppLoadingNotification,
  AppLoadingNotificationItem,
} from '../../../../web-app/src/app/interfaces/app-loading-notification';
import { HttpRequest } from '@angular/common/http';
import { urlTranslationList } from '@/shared/lib/constants/translated-urls';
import { computed } from '@angular/core';
import { isNotNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';


type NotificationState = {
  notificationData: AppLoadingNotification | null
};


const initialState: NotificationState = {
  notificationData: null
};

export const NotificationStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => {
    return {
      isLoading: computed(() => (store.notificationData()?.notificationList?.length ?? 0) > 0 && store.notificationData()?.notificationList?.some((url) => !url.isLoaded)),
    };
  }),
  withMethods((store) => ({
    setLoadingState(request: HttpRequest<any>, isLoaded: boolean) {
        const splitRequest = request.url.replace(environment.data.baseUrl, '').replace(environment.identity.baseUrl, '').split('/')[1];
        const newNotificationItem: AppLoadingNotificationItem = {
          isLoaded: isLoaded,
          description: urlTranslationList.find(f => f.request.toLowerCase() === splitRequest.toLowerCase())?.description ?? `Generic: ${splitRequest}`,
          showItem: true
        };

        let currentList = store.notificationData()?.notificationList;
        if (isNotNullOrEmpty(currentList)) {
          const index = currentList?.findIndex(f => f.description === newNotificationItem.description);
          if (index !== undefined && index > -1) {
            currentList[index] = newNotificationItem;
          } else {
            currentList.push(newNotificationItem);
          }
        } else {
          currentList = [newNotificationItem];
        }

        patchState(store, { notificationData: { isLoading: currentList?.some(f => !f.isLoaded), notificationList: currentList} });
    }
  })),
  // withStorageSync({
  //   key: `${storagePrefix}_notifications`,
  //   storage: () => localStorage,
  // })
);
