
export const navLinks = [
  {
    id: 1,
    subHeader: '',
    subPage: '/',
    subItems: [
      {
        label: 'menu.home.short',
        icon: 'home.svg',
        to: '/home',
        role: 'applicationHome',
      },
    ],
  },
  {
    id: 2,
    subHeader: '',
    subPage: '',
    subItems: [
      {
        label: 'tenantDeployment',
        icon: 'briefcase.svg',
        to: null,
        role: 'applicationHome',
      },
    ],
  },
  {
    id: 3,
    subHeader: 'menu.actions.long',
    subPage: '/deployment',
    subItems: [
      {
        label: 'menu.history.short',
        icon: 'scan-report.svg',
        to: '/deployment/history',
        role: 'deploymentHistory',
      },
      {
        label: 'menu.upload.short',
        icon: 'archive.svg',
        to: '/deployment/upload',
        role: 'deploymentUpload',
      },
      {
        label: 'menu.planning.short',
        icon: 'event.svg',
        to: '/deployment/planning',
        role: 'deploymentUpload',
      },
    ],
  },
  {
    id: 4,
    subHeader: 'menu.tenant.long',
    subPage: '/tenant',
    subItems: [
      {
        label: 'menu.devices.short',
        icon: 'settings-horizontal.svg',
        to: '/tenant/devices',
        role: 'propertiesRead',
      },
      {
        label: 'menu.deployments.short',
        icon: 'settings-vertical.svg',
        to: '/tenant/deployments',
        role: 'propertiesRead',
      },
      {
        label: 'menu.account.long',
        icon: 'account.svg',
        to: '/tenant/accounts',
        role: 'userManagement',
      },
    ],
  },
  {
    id: 5,
    subHeader: 'menu.support.long',
    subPage: '/support',
    subItems: [
      {
        label: 'menu.support-devices.long',
        icon: 'more.svg',
        to: '/support/devices',
        role: 'supportDevices',
      },
      {
        label: 'menu.tenants.long',
        icon: 'account.svg',
        to: '/support/tenants',
        role: 'supportTenants',
      },
      {
        label: 'menu.hardwaretypes.long',
        icon: 'account.svg',
        to: '/support/hardwaretypes',
        role: 'supportTenants',
      },
    ],
  },
  // {
  //   id: 6,
  //   subHeader: 'menu.analyze.long',
  //   subPage: '/analyze',
  //   subItems: [
  //     {
  //       label: 'menu.analyze-sor.long',
  //       icon: 'continues.svg',
  //       to: '/analyze/sor',
  //       role: 'sorAnalyzer',
  //     },
  //   ],
  // },
];
