import 'iconify-icon';

import { ChangeDetectionStrategy, Component, computed, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ods-custom-icon',
  standalone: true,
  templateUrl: './icon.component.html',
  imports: [
    NgOptimizedImage,
  ],
})
export class IconCustomComponent {
  icon = input.required<string>();
  isSmallIcon = input.required<boolean>();
  rotate = input<'90deg' | '180deg' | '270deg'>();
  iconClass = computed(() => {
    return this.isSmallIcon() ? 'cloudotdr-icon-small' : 'cloudotdr-icon';
  });
  iconSize = computed(() => {
    return this.isSmallIcon() ? 24 : 48;
  });
}
