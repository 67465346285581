<div class="fullwidth showAsLink" (click)="openModal()">
  <span class="single-select-header">
    @if (isTenantSelection()) {
      Tenant:
    } @else {
      Deployment:
    }
  </span>
  <span class="float-right" style="width: 25px;">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </span>
  <span class="float-right single-select-value" style="min-width: 250px;">
    @if (isTenantSelection()) {
      {{ this.deploymentStore.selectedTenant()?.name }}
    } @else {
      {{ this.deploymentStore.selectedDeployment()?.name }}
    }
  </span>
</div>
