import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, output, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { navLinks } from '@/app/components/layout/sidebar/routes';
import { TenantDeploymentPage } from '@/app/dialogs/tenant-deployment/tenant-deployment.page';
import { IconComponent } from '@/shared/lib/components/ui/icon/icon.component';
import { IconCustomComponent } from '@/shared/lib/components/ui/icon-ods/icon.component';
import { isNotNullOrEmpty, isNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { UserStore } from '@/shared/lib/stores/user.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonModule,
    CommonModule,
    IconComponent,
    IconCustomComponent,
    ImageModule,
    MenuModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
    TenantDeploymentPage,
  ],
  selector: 'ods-layout-sidebar',
  standalone: true,
  styleUrl: './sidebar.component.css',
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent {
  userStore = inject(UserStore);
  deploymentStore = inject(DeploymentStore);
  router = inject(Router);

  onLogout = output();
  isTenantDeploymentModalVisible = signal(false);
  isSideNavCollapsed = computed(() => this.userStore.isSideNavCollapsed());
  roleBasedNavLinks = computed(() => {
    return navLinks.map(navLink => ({
      ...navLink,
      subItems: navLink.subItems.filter(subItem => this.userStore.hasUserRole(subItem.role)),
    })).filter(navLink => navLink.subItems.length > 0);
  });

  toggleSideNav = () => {
    this.userStore.toggleSideNav();
  };

  logout = () => {
    this.onLogout.emit();
  };

  isLinkValid(link: string | null): boolean {
    if (isNullOrEmpty(link)) {
      return false;
    }
    const currentRoute = this.router.url;
    return currentRoute.startsWith(link!) && ((link!.length === 1 && link === currentRoute) || link!.length > 1);
  }

  protected readonly isNotNullOrEmpty = isNotNullOrEmpty;
}
