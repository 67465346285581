import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { containsWordFromList } from '@/shared/lib/core/utils/string.extension';
import { DeploymentStore } from '@/shared/lib/stores/deployment.store';
import { NotificationStore } from '@/shared/lib/stores/notification.store';
import { UserStore } from '@/shared/lib/stores/user.store';
import { environment } from '../../../../../web-app/src/environments/environment';
import { catchError, finalize, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const userStore = inject(UserStore);
  const notificationStore = inject(NotificationStore);
  const deploymentStore = inject(DeploymentStore);
  if (req.url.includes(environment.identity.baseUrl)) {
    const key = !containsWordFromList(req.url, environment.identity.unprotectedCalls) ? userStore?.token()?.token : '';
    const subscriptionKey = environment.identity.gatewayKey;

    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'ocp-apim-subscription-key': subscriptionKey,
        Authorization: `Bearer ${key}`,
      },
    });
  } else if (req.url.includes(environment.data.baseUrl)) {
    const key = userStore?.token()?.token;
    const subscriptionKey = environment.data.gatewayKey;

    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'ocp-apim-subscription-key': subscriptionKey,
        Authorization: `Bearer ${key}`,
        sub: userStore.userProfile()?.sub ?? '',
        tenantId: deploymentStore.selectedTenantId()?.toString() ?? '',
        deploymentId: deploymentStore.selectedDeploymentId()?.toString() ?? ''
      },
    });
  } else {
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'ocp-apim-subscription-key': environment.data.gatewayKey,
      },
    });
  }

  notificationStore.setLoadingState(req, false);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      finalize(() => {
        notificationStore.setLoadingState(req, true);
      })
  );
}
