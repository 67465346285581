
<ods-ui-modal
  [canDismiss]="!isLoading()"
  [focusOnShow]="true"
  [header]="'Select Tenant & Deployment'"
  [(isVisible)]="isVisible"
>
  @if (isLoading()) {
    <ods-loader class="ods-spinner-medium"></ods-loader>
  } @else {
    <ods-modal-single-select
      class="w-full"
      [isTenantSelection]="true"
    >
    </ods-modal-single-select>
    @if (!this.deploymentStore.deploymentIsLoading()) {
      <ods-modal-single-select
        class="w-full"
        [isTenantSelection]="false"
      >
      </ods-modal-single-select>
    } @else {
      <ods-loader class="ods-spinner-medium"></ods-loader>
    }
  }
</ods-ui-modal>
