<p-dialog
  [appendTo]="'body'"
  [closeOnEscape]="canDismiss()"
  [dismissableMask]="canDismiss()"
  [draggable]="false"
  [focusOnShow]="focusOnShow()"
  [header]="header()"
  [modal]="true"
  [resizable]="false"
  [(visible)]="isVisible"
  (onHide)="onVisibilityChange(false)"
  (onShow)="onVisibilityChange(true)"
>
  @if (headerTemplate()) {
    <ng-template #header>
      <ng-container *ngTemplateOutlet="headerTemplate()!" />
    </ng-template>
  }
  <ng-content />
  @if (footerTemplate()) {
    <ng-template #footer>
      <ng-container *ngTemplateOutlet="footerTemplate()!" />
    </ng-template>
  } @else if (modalForm()) {
    <div class="flex align-items-center justify-end mt-3">
      <p-button
        label="Cancel"
        severity="secondary"
        styleClass="m-1 bg-primary text-white"
        [raised]="true"
        [text]="true"
        (click)="closeModal()"
      />
      <p-button
        label="Save"
        styleClass="m-1 bg-accent text-primary"
        [disabled]="false"
        [raised]="true"
        [text]="true"
        (click)="submitForm($event)" />
    </div>
  } @else {
<!--    TODO-->
  }
</p-dialog>
