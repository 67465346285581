export const urlTranslationList = [
    {request: 'signalr', description: 'Realtime updates'},
    {request: 'deviceConfigurations', description: 'Device options'},
    {request: 'deployments', description: 'Deployments'},
    {request: 'tenants', description: 'Tenants'},
    {request: 'scans', description: 'Scans'},
    {request: 'scanExecutions', description: 'Scan details'},
    {request: 'user', description: 'Account'},
    {request: 'scanThresholds', description: 'Scan details'},
    {request: 'users', description: 'Account'},
    {request: 'other', description: 'Generic: other'},
  ];
