<aside
  class="relative flex h-full flex-col bg-primary text-white transition-all duration-300"
  [class]="isSideNavCollapsed() ? 'w-20' : 'w-64'"
>
  <div
    class="flex w-full items-center"
    [class]="isSideNavCollapsed() ? 'flex-col justify-center py-4 gap-3.5' : 'flex-row justify-between py-5 px-7'"
  >
    <p-image
      alt="ODS Logo"
      src="/assets/logo/ods.svg"
      [styleClass]="isSideNavCollapsed() ? 'flex h-5 w-11' : 'flex h-7 w-16'"
    />
  </div>

  <div class="h-px bg-primary-light text-accent" [class]="isSideNavCollapsed() ? 'mx-0' : 'mx-6'">
    @if (!isSideNavCollapsed()) {
      cloudOTDR backoffice<span class="text-secondary-light">.</span>
    }
  </div>

  <div class="flex flex-col gap-1" [class]="isSideNavCollapsed() ? 'pt-2 pb-4 px-2' : 'p-6'">
    @if (!isSideNavCollapsed()) {
      <div class="cursor-pointer rounded-md bg-primary-light px-3 py-2">
        <div class="flex items-center gap-3">
          <div class="flex flex-col gap-0.5">
            <div class="text-sm font-semibold" data-testId="firstName">{{ userStore.firstName() }}</div>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="h-px bg-primary-light" [class]="isSideNavCollapsed() ? 'mx-0' : 'mx-6'"></div>

  <div class="flex h-full flex-col justify-between overflow-y-auto overflow-x-hidden border-none py-4">
    <ul>
      @for (link of roleBasedNavLinks(); track link.id) {
        @if (isNotNullOrEmpty(link.subHeader)) {
          <li class="text-xs text-zinc-300 px-6 py-2">{{ (link.subHeader | translate) }}<span
            class="text-secondary-light">.</span></li>
        }
        <ul>
          @for (subItem of link.subItems; track subItem.label) {
            @if (subItem.label == 'tenantDeployment') {
              <li
                class="flex cursor-pointer items-center gap-3 hover:bg-primary-light/50"
                tooltipStyleClass="text-sm"
                [class]="isSideNavCollapsed() ? 'justify-center px-2 py-3' : 'px-6 py-3'"
                [pTooltip]="isSideNavCollapsed() ? this.deploymentStore.selectedDeployment()?.name : undefined"
                (click)="isTenantDeploymentModalVisible.set(!isTenantDeploymentModalVisible())"
              >
                <ods-custom-icon [icon]="subItem.icon" [isSmallIcon]="true"></ods-custom-icon>
                @if (!isSideNavCollapsed()) {
                  <span class="text-sm tracking-wide">
                    <sup>{{this.deploymentStore.selectedTenant()?.name}}</sup><br />
                    <span class="hierarchical level-1 has-parent">{{this.deploymentStore.selectedDeployment()?.name}}</span>
                  </span>
                }
              </li>
            } @else {
              <li
                class="flex cursor-pointer items-center gap-3 hover:bg-primary-light/50"
                tooltipStyleClass="text-sm"
                [class]="isSideNavCollapsed() ? 'justify-center px-2 py-3' : 'px-6 py-3'"
                [pTooltip]="isSideNavCollapsed() ? (subItem.label | translate ) : undefined"
                [routerLink]="subItem.to"
                [routerLinkActive]="isLinkValid(subItem.to) ? '!bg-secondary-light' : 'bg-primary-light'"
              >
                <ods-custom-icon [icon]="subItem.icon" [isSmallIcon]="true"></ods-custom-icon>
                @if (!isSideNavCollapsed()) {
                  <span class="text-sm tracking-wide">{{ (subItem.label | translate) }}</span>
                }
              </li>
            }
          }
        </ul>

      }
    </ul>
  </div>
    <div class="sticky bottom-0 mt-2 flex w-full flex-col">
      <p-button
        styleClass="items-center justify-center w-full rounded-none border-primary-light gap-1 py-[13.5px] px-0 hover:bg-primary-light/50 bg-primary text-white"
        tooltipStyleClass="text-sm"
        [pTooltip]="isSideNavCollapsed() ? 'Logout' : undefined"
        (onClick)="logout()"
      >
        @if (!isSideNavCollapsed()) {
          <span class="text-sm">Logout</span>
        }
        <ods-ui-icon class="text-xl" icon="ion:log-out-outline" />
      </p-button>
    </div>
  <p-button
    class="absolute bottom-32 right-0 z-10 translate-x-1/2 rounded-full bg-secondary"
    styleClass="text-white"
    [rounded]="true"
    [text]="true"
    (onClick)="toggleSideNav()"
  >
    <ods-ui-icon class="font-bold" [icon]="isSideNavCollapsed() ? 'ion:chevron-right' : 'ion:chevron-left'"/>
  </p-button>
</aside>

@if (isTenantDeploymentModalVisible()) {
  <ods-modal-tenant-deployment
    [(isVisible)]="isTenantDeploymentModalVisible"
  />
}
