@if (this.searchData() && this.searchData()?.length! > 5) {
  <mat-form-field class="fullwidth">
    <input #filter matInput placeholder="Filter" (keyup)="filterList($event)">
    @if(filter.value) {
      <button aria-label="clear" mat-icon-button matSuffix type="button" (click)="filter.value=''; filterList('');">
        <mat-icon>close</mat-icon>
      </button>
    }
  </mat-form-field>
}
<mat-dialog-content>
  @for (item of filteredItems(); track item) {
    <div [ngClass]="'search-list-row showAsLink ' + item.className" (click)="setSelection(item.id)">
      [{{ item.id }}] {{ item.name }}
    </div>
  } @empty {
    <div class="row">
      No
      @if (isTenantSelection()) {
        tenants
      } @else {
        deployments
      }
      available to select
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="button" color="accent" mat-raised-button type="button"
          (click)="cancelChanges()">{{ 'configuration.buttons.cancel' | translate }}
  </button>
</mat-dialog-actions>
