<div class="cursor-pointer" (click)="isModalVisible.set(!isModalVisible())">
  <div class="grid grid-cols-8">
    <div class="flex flex-col col-span-3 single-select-header" >
      @if (isTenantSelection()) {
        Tenant:
      } @else {
        Deployment:
      }
    </div>
    <div class="flex flex-col col-span-4 gap-1 justify-end single-select-value">
      @if (isTenantSelection()) {
        {{ this.deploymentStore.selectedTenant()?.name }}
      } @else {
        {{ this.deploymentStore.selectedDeployment()?.name }}
      }
    </div>
    <div class="flex flex-col float-right">
      <ods-ui-icon class="font-bold" [icon]="'ion:chevron-down-outline'"/>
    </div>
  </div>
</div>

@if (isModalVisible()) {
  <ods-ui-modal
    [canDismiss]="!isModalVisible()"
    [focusOnShow]="true"
    [header]="isTenantSelection() ? 'Select Tenant' : 'Select Deployment'"
    [(isVisible)]="isModalVisible"
  >

    @if (this.searchData() && this.searchData()?.length! > 5) {
      <input #filter
             placeholder="Filter"
             (keyup)="filterList($event)"
             class="p-inputtext-sm"
             id="singleSelectFilterSearch"
             pInputText
             type="text"
      >
      <p-button aria-label="clear" type="button" (click)="filter.value=''; filterList('');">
        <ods-ui-icon class="font-bold" [icon]="'ion:close-outline'"/>
      </p-button>
    }
    @for (item of filteredItems(); track item) {
      <div [ngClass]="'search-list-row cursor-pointer ' + item.className" (click)="setSelection(item.id)">
        [{{ item.id }}] {{ item.name }}
      </div>
    } @empty {
      <div class="row">
        No
        @if (isTenantSelection()) {
          tenants
        } @else {
          deployments
        }
        available to select
      </div>
    }
  </ods-ui-modal>

}
