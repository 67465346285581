@if (this.appLoadingNotification?.isLoading) {
  <div class="overlay">
    <div class="spinner-wrapper">
      <app-spinner/>
      <table>
        <thead>
        <th>Item</th>
        <th>Status</th>
        </thead>
        <tbody>
          @for (notification of this.appLoadingNotification?.notificationList; track notification) {
            <tr style="z-index: 1050">
              <td class="default-color" matListItemTitle style="z-index: 1050">{{ notification.description }}</td>
              <td matListItemLine style="z-index: 1050">
                @if (notification.isLoaded) {
                  <mat-icon color="primary">done</mat-icon>
                } @else {
                  <ods-loader class="ods-spinner-small"></ods-loader>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
}
